import React, { useState } from "react";
import { Alert, Select } from "antd";
import { useCountries } from "use-react-countries";

const PhoneInput = (props) => {
  const { type, placeholder, formik } = props;
  const { countries } = useCountries();

  const [countrycode, setCountryCode] = useState("+91");

  let options = [];
  countries.map(({ name, flags, countryCallingCode }, index) => {
    countryCallingCode &&
      countryCallingCode !== "+972" &&
      options.push({
        key: index,
        value: countryCallingCode,
        label: (
          <div className="select-flag-container">
            <img src={flags.svg} alt={name} className="country-flag" />
            <span>{countryCallingCode}</span>
            {/* <span>{name}</span> */}
          </div>
        ),
      });
  });

  const onSearch = (value) => {
    console.log("search:", value);
    return countries[0];
  };

  return (
    <div className="form_input">
      <div
        className={
          formik.errors[props.name] && formik.touched[props.name]
            ? "phone-input-container err_phone"
            : "phone-input-container"
        }
      >
        <p>
          <Select
            // defaultValue={countrycode}
            showSearch
            placeholder="Country"
            optionFilterProp="value"
            onChange={(value) => formik.setFieldValue("country_code", value)}
            // onSearch={onSearch}
            // filterOption={(input, option) =>
            //   (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
            // }
            options={options}
            defaultValue={props?.country_code || countrycode}
          />
        </p>
        <input
          name={props.name}
          type={type}
          className="inputphone"
          placeholder={placeholder}
          value={formik?.values[props.name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          defaultValue={formik?.values[props.name]}
        />
      </div>

      <p className="error_txt">
        {formik.touched[props.name] && formik.errors[props.name]}
      </p>

      {/* <p>
        {formik.errors[props.name] && formik.touched[props.name] && (
          <Alert type="error" message={formik.errors[props.name]} banner />
        )}
      </p> */}
    </div>
  );
};
export default PhoneInput;
