import Demo from "../../../../components/Slider/Slider";
import FilterItem from "../FilterItem/FilterItem";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarkets,
  getProductTypes,
} from "../../../../data/slices/productTypes";
import {
  set_product_type,
  set_market_id,
  set_is_available,
  reset,
} from "../../../../data/slices/filterBatches";
import { getProducts } from "../../../../data/slices/products";
import { cleanObject } from "../../../../helpers/cleanObj";
import useIsMounted from "../../../../hooks/useIsMountedRef";

function Filter() {
  const isMounted = useIsMounted();

  const filterParams = useSelector((state) => state.filter);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { product_types, markets } = useSelector((state) => state.types);
  const { product_type_id, market_id, is_available } = useSelector(
    (state) => state.filter
  );

  const filterTypes = {
    title: "product_type",
  };

  const filterMarket = {
    title: "Origin_Market",
  };

  const filterAvailability = {
    title: "Availability",
    items: [
      // {
      //   id: 1,
      //   value: "On Demand",
      //   label: "On Demand",
      // },
      {
        id: 2,
        value: "Available",
        label: t("filter_nav.Availability"),
      },
    ],
  };

  useEffect(() => {
    if (!isMounted.current) return;

    dispatch(getProductTypes());
    dispatch(getMarkets());
  }, []);

  //apply filters with btn
  const applyFilters = () => {
    dispatch(getProducts(cleanObject({ ...filterParams })));
  };

  return (
    <div className="filter">
      <div className="slider_filter">
        <p className="filter_text">{t("filter_nav.price")}</p>
        <Demo />
      </div>
      <div className="divider"></div>
      <FilterItem
        items={product_types}
        title={filterTypes.title}
        onChange={set_product_type}
        selectedItems={product_type_id}
        type="product_type_id"
      />
      <div className="divider"></div>
      <FilterItem
        items={markets}
        title={filterMarket.title}
        onChange={set_market_id}
        selectedItems={market_id}
        type="market_id"
      />
      <div className="divider"></div>
      <FilterItem
        items={filterAvailability.items}
        title={filterAvailability.title}
        type="is_available"
        selectedItems={is_available}
        onChange={set_is_available}
      />
      {width < 1089 && (
        <div className="action_filter">
          <button className="btn_1" onClick={applyFilters}>
            {t("filter_nav.apply_filter")}
          </button>
          <button className="btn_2" onClick={() => dispatch(reset())}>
            {t("filter_nav.cancel")}
          </button>
        </div>
      )}
    </div>
  );
}

export default Filter;
