import { Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { openModal } from "../../data/slices/modals";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "../../components/InputGenerator/InputText/InputText";
import pwdSvg from "../../assets/icons/pwd.svg";
import PhoneNumber from "../../components/InputGenerator/PhoneInput/PhoneInput";
import { useNavigate } from "react-router-dom";
import { login } from "../../data/slices/authSlice";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function Login({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
      country_code: "+91",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(4, t("validations.too_short"))
        .max(70, "Too Long!")
        .required(t("validations.required")),
      phone: Yup.string()
        .min(2, t("validations.too_short"))
        .max(70, "Too Long!")
        .required(t("validations.required")),
    }),
    onSubmit: (values) => {
      dispatch(login(values))
        .unwrap()
        .then((res) => {
          if (res.error === 1) {
            message.error(t("messages.incorrect_credentials"));
          } else {
            message.success(t("messages.login_success"));
            handleClose(id);
          }
        })
        .catch((err) => {
          console.log("err", err);
          message.error(err.message || "something went wrong");
        });
    },
  });
  let fields = [
    {
      name: "password",
      type: "password",
      placeholder: t("login.passwod_playcholder"),
      prefix: pwdSvg,
    },
    {
      name: "phone",
      type: "number",
      placeholder: t("profile.phone_number"),
    },
  ];

  return (
    <div className="login_modal">
      <Modal
        wrapClassName="modal_login_antd"
        open={open}
        onCancel={() => {
          handleClose(id);
        }}
        width="404px"
        footer={false}
      >
        <form action="" onSubmit={formik.handleSubmit}>
          <p className="title">{t("login.title")}</p>
          <p className="welcome">{t("login.welcome")}</p>
          <p className="text1">{t("login.text1")}</p>
          <div className="inputs">
            {/* <PhoneInput field={fields[1]} /> */}
            <PhoneNumber
              type="number"
              placeholder={fields[1].placeholder}
              label="Phone Number"
              name="phone"
              formik={formik}
            />
            <InputText formik={formik} field={fields[0]} />
          </div>
          <p
            className="forget_pwd"
            onClick={() => {
              // navigate("/reset-password");
              handleClose(id);
              dispatch(openModal("forgot-modal"));
            }}
          >
            {t("login.forget_password")}
          </p>
          <Button
            type="submit"
            onClick={() => {
              // navigate("/profile");
              // handleClose(id);
            }}
          >
            {loading === "idle" ? (
              t("login.Login_btn")
            ) : (
              <Spin indicator={antIcon} />
            )}
          </Button>
          <p className="without_cmpt">
            {t("login.do_not_have_account")}{" "}
            <span
              className="signup"
              onClick={() => {
                handleClose(id);
                dispatch(openModal("register-modal"));
              }}
            >
              {t("register.Register_btn")}
            </span>
          </p>
        </form>
      </Modal>
    </div>
  );
}

export default Login;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "#ffff",
    }}
    spin
  />
);
