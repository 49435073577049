import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "../../utils/publicAxios";
import axiosInstance from "../../utils/axios";

const initialState = {
  cart_products: [],
  count: 0,
  error: null,
  loading: "idle",
  add_loading: "idle",
};

export const getProductsCart = createAsyncThunk(
  "api/products_cart",
  async (query) => {
    const { key, value } = query;
    let data;
    try {
      const response = await axiosInstance.get(
        `/login/get_cart?${key}=${value}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getCount = createAsyncThunk("api/count", async (query) => {
  const { key, value } = query;
  let data;
  try {
    const response = await axiosInstance.get(
      `/login/get_cart_count?${key}=${value}`
    );
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const addProductToCart = createAsyncThunk(
  "api/add_products_cart",
  async (values) => {
    let data;
    try {
      const response = await publicAxios.post(`/login/add_cart_batch`, {
        ...values,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
//remove from cart
export const removeFromCart = createAsyncThunk(
  "api/remove_from_cart",
  async (values) => {
    let data;
    try {
      const response = await axiosInstance.post(`/login/remove_from_cart`, {
        ...values,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
export const productCartSlice = createSlice({
  name: "productCart",
  initialState,
  reducers: {},
  extraReducers: {
    // get products of cart
    [getProductsCart.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getProductsCart.fulfilled]: (state, action) => {
      const { batch } = action.payload;
      if (batch) {
        state.cart_products = batch;
      } else {
        state.cart_products = [];
      }
      state.loading = "idle";
    },
    [getProductsCart.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //add item to cart
    [addProductToCart.pending]: (state) => {
      state.error = null;
      state.add_loading = "loading";
    },
    [addProductToCart.fulfilled]: (state, action) => {
      state.add_loading = "success";
    },
    [addProductToCart.rejected]: (state, action) => {
      state.error = action.error.message;
      state.add_loading = "error";
    },
    //add count cart
    [getCount.pending]: (state) => {
      state.error = null;
      state.add_loading = "loading";
    },
    [getCount.fulfilled]: (state, action) => {
      state.add_loading = "success";
      const { count } = action.payload;
      state.count = count;
    },
    [getCount.rejected]: (state, action) => {
      state.error = action.error.message;
      state.add_loading = "error";
    },
  },
});

export const {} = productCartSlice.actions;

export default productCartSlice.reducer;
