import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "../../utils/publicAxios";
import axiosInstance from "../../utils/axios";

const initialState = {
  error: null,
  loading: "idle",
};

export const updateProfile = createAsyncThunk("api/profile", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`/user/update_user_profile`, {
      ...values,
    });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const changePassword = createAsyncThunk(
  "api/changePwd",
  async (values) => {
    let data;
    try {
      const response = await axiosInstance.post(`/user/changepassword`, {
        ...values,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [updateProfile.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [updateProfile.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
