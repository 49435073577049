import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookieValue } from "../../helpers/setDeviceData";
import axiosInstance from "../../utils/axios";
import publicAxios from "../../utils/publicAxios";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  error: null,
  message: null,
  loading: "idle",
};

export const setSession = (token) => {
  if (token) {
    localStorage.setItem("haraj_token", token);
    // axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem("haraj_token");
    // delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export const login = createAsyncThunk("auth/login", async (values) => {
  let data;
  let device_data = getCookieValue("device_data");
  try {
    const response = await axiosInstance.post(`/login/login_with_mobile`, {
      ...values,
      device_data: device_data,
    });
    data = await response.data;
    if (response.status === 200) {
      if (data.error === 0) {
        setSession(data.token, data.user);
        const userJsonString = JSON.stringify(data.user);
        localStorage.setItem("harajuser", userJsonString);
        localStorage.setItem("uid", data?.user?.uid);
        initialise({ isAuthenticated: true, user: data.user });
      }
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const register = createAsyncThunk("signup_mobile", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`/login/signup_mobile`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const verifPhone = createAsyncThunk("verifPhone", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`/login/otp`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//fill information
export const fillInfo = createAsyncThunk("api/fill_nfo", async (values) => {
  let data;
  try {
    const response = await publicAxios.post(`/login/signup_info`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//verif forgot pwd  code
export const forgotCode = createAsyncThunk(
  "api/resend_code",
  async (values) => {
    let data;
    try {
      const response = await publicAxios.post(
        `/login/forgototpverification`,
        values
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

//forgot Pwd
export const forgotPwd = createAsyncThunk("api/forgot_pwd", async (values) => {
  let data;
  try {
    const response = await publicAxios.post(
      `/login/forgotpassword_with_mobile`,
      values
    );
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//reset Pwd
export const resetPassword = createAsyncThunk(
  "api/reset_pwd",
  async (values) => {
    let data;
    try {
      const response = await publicAxios.post(`/login/resetpassword`, values);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialise: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
    },
    restore: (state) => {
      state.error = null;
      state.message = null;
    },
    logout: (state) => {
      setSession(null);
      state.isAuthenticated = false;
      state.user = null;
      window.location.href = "/";
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [login.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      // state.isAuthenticated = true;
      // state.user = user;
      state.loading = "idle";
    },
    [login.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //register
    [register.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [register.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [register.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //forgot pwd
    [forgotPwd.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [forgotPwd.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [forgotPwd.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //reset pwd
    [resetPassword.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [resetPassword.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { initialise, logout, restore } = authSlice.actions;

export default authSlice.reducer;
