import { useState } from "react";
import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { set_cost_range } from "../../data/slices/filterBatches";

const Demo = () => {
  const [values, setValues] = useState([0, 9000]);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  function setRange(v) {
    dispatch(set_cost_range(v));
  }
  return (
    <div>
      <Slider
        reverse={currentLanguageCode === "ar"}
        range
        defaultValue={[0, 9000]}
        max={9000}
        onAfterChange={(v) => {
          setRange(v);
        }}
        onChange={(v) => {
          setValues(v);
        }}
      />
      <div className="values_slider">
        <p>
          {values[0]} {t("filter_nav.r_s")}
        </p>
        <p>
          {values[1]} {t("filter_nav.r_s")}
        </p>
      </div>
    </div>
  );
};

export default Demo;
