import HamburgerMenuTrigger from "../HamburgerMenuTrigger";
import useWindowSize from "../../hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import en from "../../assets/icons/en.svg";
import loginIcon from "../../assets/icons/login.svg";
import registerIcon from "../../assets/icons/signup.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Cart from "../CartHeader/Cart";
import cookies from "js-cookie";
import { openModal } from "../../data/slices/modals";
import logoImg from "../../assets/images/bwLogo.png";
import Logo from "../Logo/Logo";
import { logout } from "../../data/slices/authSlice";
import logouIcon from "../../assets/icons/logout.svg";

const Header = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { isAuthenticated, user } = useSelector((state) => state.auth);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const token = localStorage.getItem("haraj_token");
  const user = JSON.parse(localStorage.getItem("harajuser"));
  return (
    <>
      <header className="header">
        <Logo img={logoImg} />

        <div className="header__right">
          {width > 1090 && (
            <>
              <NavLink className="item_header link_header" to="/about">
                {t("header.about_as")}
              </NavLink>
              <div className="item_header">
                <Cart />
              </div>
              {!token ? (
                <>
                  <button
                    className="item_header sign_up_header"
                    onClick={() => dispatch(openModal("register-modal"))}
                  >
                    <img src={registerIcon} alt="" /> {t("header.register")}
                  </button>
                  <button
                    className="login_header"
                    onClick={() => dispatch(openModal("login-modal"))}
                  >
                    <img src={loginIcon} alt="" />
                    {t("header.login")}
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="login_header"
                    onClick={() => navigate("/profile")}
                  >
                    {user?.name}
                  </button>
                  <div className="exit_btn" onClick={() => dispatch(logout())}>
                    <img src={logouIcon} alt="" />
                  </div>
                </>
              )}

              <button
                className="item_header lang_header"
                onClick={() => {
                  if (currentLanguageCode === "en") {
                    i18next.changeLanguage("ar");
                  } else {
                    i18next.changeLanguage("en");
                  }
                }}
              >
                {t("language")}
                <img src={en} alt="" />
              </button>
            </>
          )}
          {width < 1091 && <div></div>}
          <HamburgerMenuTrigger />
        </div>
      </header>
    </>
  );
};

export default Header;
