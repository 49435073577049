import "./_Logo.scss";
import cookies from "js-cookie";
import { NavLink } from "react-router-dom";

function Logo({ img }) {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <NavLink style={{ cursor: "pointer" }} to="/">
      <img
        className={
          currentLanguageCode === "en" ? "img_logo logo_en" : "img_logo logo_ar"
        }
        src={img}
        alt=""
      />
    </NavLink>
  );
}

export default Logo;
