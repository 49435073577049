import * as React from "react";
import { Spin } from "antd";
import "./_Spinner.scss";
export default function Progress() {
  return (
    <div className="circlar_progress_container">
      <Spin size="large" />
    </div>
  );
}
