import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  country_code: "IN",
  product_category_id: [],
  product_type_id: [],
  product_grade_id: [],
  market_id: [],
  cost_rang: null,
  is_available: null,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    set_product_category: (state, action) => {
      state.product_category_id = [action.payload];
    },
    set_is_available: (state, action) => {
      if (state.is_available === null) {
        state.is_available = "available";
      } else if (state.is_available === "available") {
        state.is_available = null;
      }
    },
    set_product_type: (state, action) => {
      const newValues = [...state.product_type_id];
      const index = newValues.indexOf(action.payload);
      if (index > -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(action.payload);
      }

      state.product_type_id = newValues;
    },
    set_market_id: (state, action) => {
      const newValues = [...state.market_id];
      const index = newValues.indexOf(action.payload);
      if (index > -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(action.payload);
      }

      state.market_id = newValues;
    },
    set_cost_range: (state, action) => {
      let max = action.payload[1];
      let min = action.payload[0];
      state.cost_rang = `${min}-${max}`;
    },
    selectAll: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    reset: (state) => {
      state.product_type_id = [];
      state.market_id = [];
      state.is_available = null;
      state.cost_rang = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_product_category,
  set_product_type,
  set_market_id,
  selectAll,
  set_cost_range,
  set_is_available,
  reset,
} = filterSlice.actions;

export default filterSlice.reducer;
