import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "../../utils/publicAxios";
import axiosInstance from "../../utils/axios";

const initialState = {
  products: [],
  error: null,
  loading: "idle",
};

export const getProducts = createAsyncThunk("api/products", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`/login/get_batches`, {
      ...values,
    });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getProducts.fulfilled]: (state, action) => {
      const { batch } = action.payload;
      state.products = batch;
      state.loading = "idle";
    },
    [getProducts.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const {} = productsSlice.actions;

export default productsSlice.reducer;
