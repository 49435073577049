import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import PhoneNumber from "../../components/InputGenerator/PhoneInput/PhoneInput";
import { openModal } from "../../data/slices/modals";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputSelect from "../../components/InputGenerator/InputSelect/InputSelect";
import { register } from "../../data/slices/authSlice";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const Register = ({ id, open, handleClose, data, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  let field = {
    name: "phone",
    type: "number",
    placeholder: t("profile.phone_number"),
  };
  // let countryField = {
  //   name: "country_code",
  //   type: "select",
  //   placeholder: t("profile.country"),
  //   prefix: false,
  // };
  const formik = useFormik({
    initialValues: {
      phone: "",
      country_code: "+91",
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .min(2, t("validations.too_short"))
        .max(70, "Too Long!")
        .required(t("validations.required")),
    }),
    onSubmit: (values) => {
      dispatch(register(values))
        .unwrap()
        .then((res) => {
          if (res.error === 1) {
            message.error(res.message || "something went wrong");
          } else {
            message.success(res.message);
            handleClose(id);
            navigate("phone-verification", { state: { user: res.user } });
          }
        })
        .catch((err) => {
          console.log("err", err);
          message.error(err.message || "something went wrong");
        });
    },
  });
  return (
    <div className="login_modal">
      <form onSubmit={formik.handleSubmit}>
        <Modal
          wrapClassName="modal_login_antd"
          open={open}
          onCancel={() => {
            handleClose(id);
          }}
          width="404px"
          footer={false}
        >
          <p className="title">{t("register.title")}</p>
          <p className="welcome">{t("register.welcome")}</p>
          <p className="text1">{t("register.text1")}</p>
          <div className="inputs">
            <PhoneNumber
              type="number"
              placeholder={field.placeholder}
              label="Phone Number"
              name="phone"
              formik={formik}
            />
            {/* <InputSelect formik={formik} field={countryField} prefix={false} /> */}
            <Button
              onClick={() => {
                formik.handleSubmit();
                // handleClose(id);
                // navigate("phone-verification");
              }}
            >
              {loading === "idle" ? (
                t("register.Register_btn")
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
          </div>

          <p className="without_cmpt">
            {t("register.have_account")}{" "}
            <span
              className="signup"
              onClick={() => {
                handleClose(id);
                dispatch(openModal("login-modal"));
              }}
            >
              {t("login.Login_btn")}
            </span>
          </p>
        </Modal>
      </form>
    </div>
  );
};

export default Register;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "#ffff",
    }}
    spin
  />
);
